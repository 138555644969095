/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/css/tailwind.scss';
import './src/css/style.scss';


export const onRouteUpdate = () => {
    if (typeof window !== `undefined`) { window.scrollTo(0, 0)}
};
  
export const shouldUpdateScroll = args => {
    return false;
};